.m-w-190 {
    min-width: 190px;
}
.m-w-140 {
    min-width: 140px;
}
.m-w-150 {
    min-width: 150px;
}
.m-w-160 {
    min-width: 160px;
}
.m-w-170 {
    min-width: 170px;
}
.m-w-180 {
    min-width: 180px;
}
.m-w-220 {
    min-width: 220px;
}
.m-w-300 {
    min-width: 300px;
}

.gap-16 {
    column-gap: 16px;
}

.w-full {
    width: 100%;
}

.diagnosis {
    width: 100%;
}
.service {
    width: 100%;
}
@media screen and (min-width: 1537px) {
    .col-2xl {
        flex: 1 0 0%;
    }
    .diagnosis {
        width: 40%;
    }
    .service {
        width: 60%;
    }
}
