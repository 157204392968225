@import "_client-theme";

@include themify($themes) {
    .red {
        color: themed("secondary_color");
    }

    .primary {
        color: themed("primary_color");
    }

    .btn {
        &.btn-primary {
            background: themed("primary_color");
            border: 1px solid themed("primary_color");
            color: themed("white_color");
        }

        &.btn-border-primary {
            background: transparent;
            border: 1px solid themed("primary_color");
            color: themed("primary_color");
        }
    }
    .pin-info-color {
        color: themed("pin_text_info");
    }
    .custom-top-header-alert {
        border: 1px solid themed("subinfo_aboutme");
        .toast-body {
            background: themed("info_aboutme");
            color: themed("primary_text");
        }

        .btn-primary {
            background-color: themed("subinfo_aboutme");
            border-color: themed("subinfo_aboutme");
        }
    }
    .form-label {
        color: themed("black_color");
        span {
            color: themed("secondary_color");
        }
    }
    .post-logout {
        .logout-txt {
            color: themed("primary_topnav_color");
        }
        .sub-logout-txt {
            span {
                color: themed("primary_topnav_color");
            }
        }
        path {
            fill: themed("primary_topnav_color");
        }
    }
    .form-check-input:checked {
        background-color: themed("primary_color");
        border-color: themed("primary_color");
    }
    .custom-loader-info {
        background: themed("morning_mist");
        color: themed("primary_topnav_color");
    }

    .form-content {
        .form-control {
            background: themed("shaded_white");
            border: 1px solid themed("winter_scene");

            &.is-valid,
            &:focus {
                border: 1px solid themed("is_valid_border");
                background: themed("is_valid_background");

                ~ .field-icon {
                    i {
                        color: themed("is_valid_border");
                    }
                    .svg-inline--fa {
                        path {
                            fill: themed("is_valid_border");
                        }
                    }
                }
            }

            &.is-invalid,
            &.is-invalid:focus {
                border: 1px solid themed("apricot_haze");
                background: themed("sugar_milk");

                ~ .field-icon {
                    i {
                        color: themed("secondary_color");
                    }
                    .svg-inline--fa {
                        path {
                            fill: themed("secondary_color");
                        }
                    }
                }
            }

            // &.is-valid {
            //     ~ .field-icon {
            //         i {
            //             color: themed("is_valid_border");
            //         }
            //         .svg-inline--fa {
            //             path {
            //                 fill: themed("is_valid_border");
            //             }
            //         }
            //     }
            // }
        }

        .field-icon {
            i {
                color: themed("sea_leon");
            }
            .svg-inline--fa {
                path {
                    fill: themed("sea_leon");
                }
            }
        }
    }

    .main-content {
        .card-form {
            border-bottom: 1px solid themed("morning_mist");
        }
    }

    .card-content {
        table {
            thead {
                background: themed("table_header_with_alpha_26");
            }
            tbody {
                tr:hover {
                    background-color: themed("is_valid_background");
                }
            }
            td {
                border-color: themed("morning_mist");
                a {
                    color: themed("primary_color");
                }
            }
            tr {
                &:last-child {
                    td {
                        border: none;
                    }
                }
            }
        }
    }

    .page-item {
        button {
            color: themed("black_color");
        }
    }

    .authentication-main {
        background: themed("background_color");

        .authentication-left {
            background: #eee;
        }
        .authentication-right {
            background: themed("white_color");
        }

        .authentication-title {
            h1 {
                color: themed("primary_color");
            }
            span {
                color: themed("volcanic_rock");
            }
        }
    }

    .top-bar {
        &.sb-topnav {
            background-color: themed("primary_topnav_color");
        }
    }

    .custom-tab {
        .nav {
            color: themed("primary_color");
        }
        .nav-tabs {
            .nav-link {
                color: themed("primary_color");
                &.active {
                    background: themed("primary_color");
                    color: themed("white_color");
                }
            }
        }
    }

    .sb-sidenav {
        button {
            color: themed("sea_leon");
            &:active {
                color: themed("sea_leon");
            }
        }
        .sb-sidenav-menu {
            .nav {
                .nav-link {
                    .sb-nav-link-icon,
                    span {
                        color: themed("primary_color");
                    }
                    &:hover {
                        background: themed("primary_light_color");
                        .sb-nav-link-icon,
                        span {
                            color: themed("primary_color");
                        }
                    }
                    &.active {
                        background: themed("menu_active_background");
                        .sb-nav-link-icon,
                        span {
                            color: themed("menu_active_color");
                        }
                    }
                }
            }
        }
    }

    .user-info-page {
        .card {
            i {
                color: themed("primary_color");
            }
            &.info-card {
                background: themed("primary_color");
            }
            &.cms-card {
                background: themed("info_aboutme");
                .mem-info {
                    .member-info {
                        color: themed("subinfo_aboutme");
                    }
                    .member-name {
                        color: themed("primary_text");
                        .mem-mini-info {
                            color: themed("subinfo_aboutme");
                        }
                    }
                }
            }
            .reported-info {
                color: themed("subinfo_aboutme");
            }
            .cms-switch {
                background: themed("switch_background");
                .sb-nav-link-icon {
                    color: themed("primary_color");
                }
                .warn-icon {
                    color: themed("subinfo_aboutme");
                }
                span {
                    color: themed("primary_text");
                }
                .form-switch {
                    .form-check-input {
                        //  background-color: themed("light_slate_gray");
                        &:checked {
                            background-color: themed("subinfo_aboutme");
                            border-color: themed("subinfo_aboutme");
                        }
                    }
                }
            }
        }
    }
    .ins-switch {
        background: themed("shaded_white");
        border: 1px solid themed("winter_scene");
        .form-switch {
            .form-check-input {
                //  background-color: themed("light_slate_gray");
                &:checked {
                    background-color: themed("primary_color");
                    border-color: themed("primary_color");
                }
            }
        }
    }
    .custom-filter-select {
        .css-13cymwt-control {
            background: themed("shaded_white");
            border: 1px solid themed("winter_scene");
        }
        .css-t3ipsp-control {
            background: themed("primary_light_color");
            box-shadow: 0 0 0 1px themed("primary_color") !important;
        }

        .css-t3ipsp-control:hover {
            background: themed("primary_light_color");
            box-shadow: 0 0 0 1px themed("primary_color") !important;
        }

        .css-1xc3v61-indicatorContainer {
            color: #7d8998;
        }
    }
    .header-dropdown {
        a {
            color: themed("primary_color");

            &:hover {
                background: themed("primary_light_color");
            }
        }
    }

    .label-info-main {
        i {
            color: themed("primary_color");
        }
    }

    .hours-block {
        > div {
            background: rgba(0, 84, 164, 0.05);
        }
    }

    .label-info-main {
        > div {
            span {
                a {
                    color: themed("primary_color");
                }
            }
        }
    }

    .information-icon {
        color: themed("primary_color");
    }

    .form-field {
        &.is-invalid {
            .react-datepicker__input-container::after {
                color: themed("secondary_color");
            }
        }
        &.is-valid {
            .react-datepicker__input-container::after {
                color: themed("is_valid_border");
            }
        }
        .react-datepicker__input-container::after {
            color: themed("sea_leon");
        }
    }

    .react-datepicker {
        border-color: themed("winter_scene");
    }

    .react-datepicker__header {
        background-color: themed("primary_color");
    }

    .react-datepicker__day-name,
    .react-datepicker__year-select,
    .react-datepicker__month-select {
        color: themed("white_color");
    }

    select.react-datepicker__month-select,
    select.react-datepicker__year-select {
        option {
            color: themed("black_color");
        }
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
        border-bottom-color: themed("shaded_white");
    }

    .react-datepicker__header,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: themed("winter_scene");
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: themed("primary_color");
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: themed("is_valid_background");
        color: themed("is_valid_border");
    }

    .react-datepicker__day--today {
        background-color: themed("light_gray_color");
        &.react-datepicker__day--selected {
            background-color: themed("primary_color");
        }
    }

    .menu-toggle-icon {
        color: themed("sea_leon");
    }

    .authentication-footer span,
    .total-record {
        color: themed("volcanic_rock");
    }

    .pagination {
        .page-link {
            border-color: themed("primary_color");
            i {
                color: themed("primary_color");
            }
            &:hover,
            &:focus {
                background: transparent;
            }
            &.current-page {
                cursor: default;
                border-color: themed("primary_color");
                background: themed("primary_color");
                color: themed("white_color");
            }
            &[disabled] {
                cursor: not-allowed;
                border-color: themed("gray_color");
                background: themed("white_color");
                i {
                    color: themed("gray_color");
                }
            }
        }
    }

    .stepper {
        .step {
            &:not(:last-child)::after {
                background: #8e8e8e;
            }
            span {
                background: themed("secondary_text");
                color: themed("white_color");
            }
            label {
                color: themed("secondary_text");
            }
            &.active {
                span {
                    background: themed("primary_color");
                    color: themed("white_color");
                }
                label {
                    color: themed("primary_color");
                }
            }
            &.disabled {
                span {
                    background: themed("secondary_text");
                    color: themed("white_color");
                }
                label {
                    color: themed("secondary_text");
                }
            }
            &.success {
                span {
                    background: themed("green_color");
                    color: themed("white_color");
                }
                label {
                    color: themed("green_color");
                }
            }
        }
    }
    .theme-authentication-screen {
        background-color: themed("primary_color");
        .theme-authentication-left {
            p {
                color: themed("white_color");
                &::after {
                    content: "";
                    background: themed("white_color");
                }
            }
        }
        .theme-logo {
            .theme-version {
                color: themed("secondary_text");
            }
        }
        .theme-title-main {
            .theme-title {
                color: themed("primary_text");
            }
        }
        .theme-welcome-text {
            .theme-title {
                color: themed("primary_text");
            }
            span {
                color: themed("secondary_text");
            }
        }
        .theme-information-message {
            background: themed("info_aboutme");
            color: themed("primary_text");
            a,
            b,
            p {
                color: themed("subinfo_aboutme");
            }
        }
        .theme-authentication-right {
            background: themed("white_color");
        }
        .theme-links {
            .theme-link-single {
                a {
                    color: themed("primary_color");
                }
                span {
                    color: themed("secondary_text");
                }
            }
        }
        .copyright-poweredby {
            .theme-copyright {
                color: themed("secondary_text");
            }
            .theme-powered-by {
                span {
                    color: themed("secondary_text");
                }
            }
        }
        .alpha-beta-text {
            background-color: themed("primary_topnav_color");
            color: themed("white_color");
        }
    }

    .form-switch {
        .form-check-input {
            border: 0;
            background-color: themed("sea_leon");
        }
    }
}

.theme-healthsun {
    .btn.btn-primary {
        background: #fdb718;
        border: 1px solid #fdb718;
        color: #183d6a;
    }
}
